import { isCallChain } from 'typescript'
import api, { genHeader } from './API'
import api_ext from './API-EXT'
export default {
  get(token: string | undefined, { seed, page, limit = 10, tag_name, lng, search, sort_by, user_id, collection_id, category_id, has_collection, top_dl_in_week, category, category_exclude, mime_type, query_exclude }: any) {
    // return api.get('/video', {
    //   params: {
    //     page,
    //     limit,
    //     tag_name: search,
    //     lng,
    //     sort_by,
    //   },
    // })

    return api.get(token ? '/user/video' : '/video', {
      params: {
        page,
        limit,
        seed,
        tag_name,
        lng,
        search,
        sort_by,
        user_id,
        collection_id,
        category_id,
        has_collection,
        top_dl_in_week,
        category,
        category_exclude,
        mime_type,
        query_exclude
      },
      ...genHeader(token),
    })

    // return require('./__mocks__/videos.json')
  },
  getById({ id, lng, is_creator, user_id }: any) {
    return api.get('/video', {
      params: {
        id,
        lng,
        is_creator,
        user_id,
      },
    })
    // return require('./__mocks__/video.json')
  },
  getSimilar({ page, next, seed, limit = 10, video_id, lng }: any) {
    return api.get('/video/similar', {
      params: {
        page,
        limit,
        seed,
        video_id,
        next,
        lng,
      },
    })
    // return require('./__mocks__/similar-videos.json')
  },
  getRelatedTags() {
    const data = require('./__mocks__/related-tags.json')
    return data
  },
  getVideosByTag({ page, tag_name, limit, lng, sort_by }: any) {
    return api.get('/video', {
      params: {
        page,
        limit,
        tag_name,
        lng,
        sort_by,
      },
    })
  },
  download(token: string, { stock_id }: any) {
    return api.post('/video/download', {
      stock_id,
    }, genHeader(token))
  },
  view({ id }: any) {
    return api.post('/video/view', {
      id,
    })
  },
  getDownloadInfo(token: string, { lng }: any) {
    return api.get('/video/download/info', {
      params: {
        lng,
      },
      ...genHeader(token),
    })
  },
  getDownloadHistory(token: string, { sort_by, limit, page }: any) {
    return api.get('/user/downloadHistory', {
      params: {
        sort_by,
        limit,
        page,
      },
      ...genHeader(token),
    })
  },
  hideDownloadHistory(token: string, { ids }: any) {
    return api.delete('/user/downloadHistory', { data: { ids },
      ...genHeader(token) })
  },

  update(token: string, { id, is_deleted, meta, hide_reason, auto_status }: any) {
    return api.put('/video', {
      id,
      is_deleted,
      meta,
      hide_reason,
      auto_status,
    }, genHeader(token))
  },

  updateData(token: string, { video }: any) {
    return api.put('/updateData', {
      video,
    }, genHeader(token))
  },

  hide(token: string, { id, auto_status }: any) {
    return api.put('/video', {
      id,
      show: false,
      hide_reason: 'クリエイター自身が非表示にしました。',
      auto_status
    }, genHeader(token))
  },

  createViolation({ video_id, email, detail }: any) {
    return api.post('/violation', {
      video_id,
      email,
      detail,
    })
  },
  createDefect({ video_id, file_type, detail }: any) {
    return api.post('/defect', {
      video_id,
      file_type,
      detail,
    })
  },
  showVideo(token: string, { id, auto_status, displayed_at }: any) {
    return api.put('/showVideo', {
      id,
      show: true,
      hide_reason: '',
      auto_status,
      displayed_at
    }, genHeader(token))
  },
  addCopyright(token: string, { copyRight, video_id }: any, hide_reason: string, agreeURL:boolean) {
    return api.post('/creator/addCopyright', {
      copyRight,
      video_id,
      agreeURL,
      hide_reason,
    }, genHeader(token))
  },
  getCopyrightList(token: string) {
    return api_ext.get('/creator/getCopyrightList', {
      ...genHeader(token),
    })
  },
  deleteCopyright(token: string, { copyright_id }: any) {
    return api.delete('/creator/deleteCopyright', {
      data: {
        copyright_id,
      },
      ...genHeader(token),
    })
  },
  getByMulId({ ids }: any) {
    return api_ext.post('/video/multiple', {
      ids,
    })
  },
  updateThumbnail(token: string, { id, thumbnail_time }: any) {
    return api_ext.post('/video/updateThumbnail', {
      id,
      thumbnail_time,
    }, genHeader(token))
  },
}
